/* Navbar.css */

/* Style for the navbar container */
/* nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f9f9f9;
    color: #fff;
    height: 50px;
  } */
  
  nav {
    padding: 10px auto;
    background-color: #f9f9f9;
    color: #fff;
    height: 60px;
    font-family: AvenirBook;
  }

  #content-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1000px;
    margin: auto auto;
  }
  /* Style for the left content (sparknote) */
  .left-content {
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 100%;
  }
  /* Navbar.css */

  /* Style for the logo image */
  .logo {
    width: 40px; /* Adjust the width as needed */
    height: 40px; /* Adjust the height as needed */
    margin-right: 10px; /* Adjust the margin as needed */
  }

  /* Style for the app name text */
  .app-name {
    font-size: 1.5rem; /* Adjust the font size as needed */
    font-weight: bold; /* Adjust the font weight as needed */
    color: black; /* Adjust the text color as needed */
    /* display: block; /* Make the span a block-level element to center the text */
    /* text-align: center; Center the text horizontally */
    display: inline-block; /* Make the span an inline-block element */
    line-height: 2;
  }


  /* Style for the right content */
  .right-content {
    display: flex;
    align-items: center;
    height: 100%;
  }
  
  /* Style for the person icon */
  .person-icon {
    font-size: 1.2rem;
    margin-right: 10px;
  }
  
  /* Style for login and register links/buttons */
  .right-content a,
  .right-content button {
    text-decoration: none;
    color: #973e95;
    font-size: 1rem;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 4px;
    border: 2px solid #973e95;
    font-size: 18px;
    height: 70%;
    width: 80px;
    text-align: center; /* Center the text horizontally */
    display: flex; /* Use flexbox to center vertically */
    align-items: center; /* Center the text vertically */
    justify-content: center; /* Center the text horizontally */
    text-decoration: none;
  }
  
  /* Hover effect for login and register links/buttons */
  .right-content a:hover,
  .right-content button:hover {
    text-decoration: underline;
  }
  