body {
  font-family: Arial, sans-serif;
  margin: 0;
}

.page-container {
  margin: 20px auto;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  font-family: AvenirBook;
}

#memo-container {
  max-width: 600px;
  margin: 0 auto;
}
.memo {
  width: 100%;
  padding: 20px;
  margin: 20px 0;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added box shadow */
}
#memo-input {
  width: 100%;
  padding: 8px;
  border: 2px solid #ccc; /* Changed border width to 2px */
  outline: none; /* Remove the default outline */
}
#save-button {
  margin-top: 8px;
  padding: 8px 16px;
  background-color: white;
  color: black;
  border: 2px solid black;
  cursor: pointer;
}
#save-button:hover {
  background-color: lightgray;
}

@font-face {
  font-family: 'SourceHanSerif-Light'; /* Give your font a unique name */
  src: url('./fonts/SourceHanSerifSC-Light.otf') format('opentype'); /* Specify the path to your OTF file */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SourceHanSerif-Regular'; /* Give your font a unique name */
  src: url('./fonts/SourceHanSerifSC-Regular.otf') format('opentype'); /* Specify the path to your OTF file */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SourceHanSerif-Bold'; /* Give your font a unique name */
  src: url('./fonts/SourceHanSerifSC-SemiBold.otf') format('opentype'); /* Specify the path to your OTF file */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirBook'; /* Give your font a unique name */
  src: url('./fonts/avenir_book.ttf') format('truetype'); /* Specify the path to your OTF file */
  font-weight: normal;
  font-style: normal;
}