/* PersonalCenter.css */
  /* .center-content {
    text-align: center;
    padding: 20px;
    background-color: white;
    height: 100%;
    font
  } */
  
  h2 {
    color: #333; /* Set heading color */
  }
  
  p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .logout-button {
    padding: 10px 20px;
    background-color: #ff5722; /* Set button background color */
    color: #fff; /* Set button text color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .logout-button:hover {
    background-color: #e64a19; /* Change button color on hover */
  }
  