/* Register.css */
/* Reuse styles from Login.css */

/* Apply the same styles as the login form */
.register-form {
    background-color: #fff;
    width: 100%;
    font-size: 20px;
  }
  
  /* Apply the same styles as the input fields */
  .register-form input {
    width: 100%;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    outline: 1px solid grey;
  }
  
  /* Apply the same styles as the form groups */
  .register-form .form-group {
    margin-bottom: 20px;
  }
  
  /* Apply the same styles as the labels */
  .register-form label {
    font-weight: bold;
  }
  
  /* Apply the same styles as the form controls (input fields) */
  .register-form .form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* Apply the same styles as the register button */
  .register-button {
    width: 100%;
    margin-top: 20px;
    height: 40px;
    background-color: #973e95;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  